import { HttpEventType, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import { ApiResponse } from '../interfaces/generic/api-response';
import { ResourceFilterData, ResourceFiltersResult } from '../interfaces/resource';
import { map } from 'rxjs';

export const orderFiltersInterceptor: HttpInterceptorFn = (req, next) => {
  const isEndpointGetFilters = req.url.endsWith('/action/catalogue/filters') || req.url.includes('/action/catalogue/filters?');

  if (isEndpointGetFilters) {
    return next(req)
      .pipe(
        map(
          (x) => {
            if (x.type !== HttpEventType.Response) return x;

            const newResponse = cloneDeep(x) as HttpResponse<ApiResponse<ResourceFiltersResult>>;

            if (!newResponse.body || !x.body || !newResponse.body.result.filters || !Object.keys(newResponse.body.result.filters).length) return x;

            newResponse.body!.result.filters = Object.entries(newResponse.body.result.filters).reduce((p, [filterName, filterData]) => {
              return {
                ...p,
                [filterName]: (filterData as ResourceFilterData[]).sort((a, b) => ((a.label || a.value) > (b.label || b.value)) ? 1 : ((b.label || b.value) > (a.label || a.value)) ? -1 : 0),
              }
            }, {});

            return newResponse;
          }
        )
      )
  }

  return next(req);
};
