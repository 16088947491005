import { Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-modal-generic-error',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
    MatButtonModule,
  ],
  templateUrl: './modal-generic-error.component.html',
  styleUrl: './modal-generic-error.component.scss'
})
export class ModalGenericErrorComponent {
  reloadPage(): void {
    window.location.href = window.location.origin;
  }
}
