<div class="grid grid-cols-12">
  <div class="col-span-11">
    <ng-container #fieldComponent></ng-container>
  </div>
  <div class="flex flex-col">
    <button mat-icon-button color="primary" (click)="onNavigateUrl()">
      <mat-icon fontIcon="subdirectory_arrow_right"></mat-icon>
    </button>
  </div>
</div>
