import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormlyService {
  action: BehaviorSubject<string | null> = new BehaviorSubject<string | null | null>(null);
  action$: Observable<string | null> = this.action.asObservable();

  constructor() {}
  setAction(action: string | null) {
    this.action.next(action);
  }
}
