import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { FetchedItem } from '../../interfaces/generic/fetched-item';
import { UserProfile } from '../../interfaces/auth';

export const initialState: FetchedItem<UserProfile> & { isComplete: boolean } = {
    isLoading: false,
    isComplete: false,
    errorMsg: null,
    data: null,
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.tryLogin, (state) => ({
        isLoading: true,
        isComplete: false,
        errorMsg: null,
        data: null,
    })),
    on(AuthActions.tryLoginSuccess, (state, { userProfile }) => ({
        isLoading: false,
        isComplete: true,
        errorMsg: null,
        data: userProfile,
    })),
    on(AuthActions.tryLoginFailure, (state, { error }) => ({
        isLoading: false,
        isComplete: true,
        errorMsg: error,
        data: null,
    })),
    on(AuthActions.logout, (state) => ({
        isLoading: true,
        isComplete: false,
        isUpdatingProfile: false,
        isUpdatingProjects: false,
        errorMsg: null,
        data: null,
    })),
    on(AuthActions.logoutSuccess, (state) => ({
        isLoading: false,
        isComplete: true,
        isUpdatingProfile: false,
        isUpdatingProjects: false,
        errorMsg: null,
        data: null,
    })),
    on(AuthActions.logoutFailure, (state, { error }) => ({
        isLoading: false,
        isComplete: true,
        isUpdatingProfile: false,
        isUpdatingProjects: false,
        errorMsg: error,
        data: null,
    })),
);
