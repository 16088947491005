import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'app-repeat-type',
  standalone: true,
  imports: [MatIconModule, FormlyModule, MatButtonModule],
  templateUrl: './repeat-type.component.html',
  styleUrl: './repeat-type.component.scss',
})
export class RepeatTypeComponent extends FieldArrayType {}
