import { environment } from '../environments/environment';

interface EndpointDetection {
  baseURL: string;
  endpoint: string;
  type: 'match' | 'prefix';  
};

export const authEndpoints: EndpointDetection[] = [
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/catalogue',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: environment.toolApiEndpoint,
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/oms/action/user',
    type: 'prefix',
  },
];

export const excludedEndpointsFromGlobalError: EndpointDetection[] = [
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/action/credits/projects/',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/action/admin/profiles/',
    type: 'prefix',
  },
];
