import { Component, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { filter, take } from 'rxjs';
import { AuthService } from './core/services/misc/auth.service';
import { selectIsAuthComplete } from './core/state/auth/auth.selectors';
import { AuthActions } from './core/state/auth/auth.actions';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  isReady = signal(false);

  constructor(private store: Store, private authService: AuthService) {}

  async ngOnInit(): Promise<void> {
    this.initAuth();
  }

  async initAuth(): Promise<void> {
    this.store.select(selectIsAuthComplete)
      .pipe(
        filter((x) => x),
        take(1),
      )
      .subscribe(() => {
        this.isReady.set(true);
        this.authService.navigateAfterLogin();
      });

    if (environment.skipAuthInitialization) {
      this.isReady.set(true);
      return;
    };

    this.store.dispatch(AuthActions.tryLogin());
  }
}
