import { HttpContext, HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, of, switchMap } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN, excludedEndpointsFromGlobalError } from '../../endpoints.config';
import { errorCodes } from '../../error-codes.config';

import { covertErrorToBaseResponse, isBaseResponse } from '../utilities/error-utils';

import { ModalGenericErrorComponent } from '../../shared/components/modal-generic-error/modal-generic-error.component';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog) { }

  openErrorModal(message: string | null): void {
    this.dialog.open(ModalGenericErrorComponent, { disableClose: true, data: { message } });
  }

  private isUrlExcludedFromGlobalError(url: string, httpContext: HttpContext): boolean {
    if (httpContext.get(EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN) === false) return false;
    if (httpContext.get(EXCLUDE_FROM_GLOBAL_ERROR_CONTEXT_TOKEN) === true) return true;

    const getEndpointFromURL = (url: string) => '/' + url.split('//').join('').split('?')[0].split('/').filter((y, i) => i > 0).join('/');

    return excludedEndpointsFromGlobalError.some(
      (endpoint) =>
        url?.startsWith(endpoint.baseURL)
        && (endpoint.type === 'match'
          ? getEndpointFromURL(url) === endpoint.endpoint
          : getEndpointFromURL(url).startsWith(endpoint.endpoint)
        )
    )
  }

  private getErrorModalMessage(url: string, statusCode: number, serviceStatusCode: number | null, messageCode: string | null): string | null {
    const errorCodesForRequestAPI = Object.entries(errorCodes).find(([k]) => url.startsWith(k))?.[1] || [];

    const result = errorCodesForRequestAPI.find((x) => x.messageCode === messageCode)?.message
      || errorCodesForRequestAPI.find((x) => x.serviceStatusCode === serviceStatusCode)?.message
      || errorCodesForRequestAPI.find((x) => x.statusCode === statusCode)?.message
      || null;

    console.debug('error-interceptor', url, messageCode, result);
    return result;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const response = covertErrorToBaseResponse(err);
        console.debug('error-interceptor', response);

        return of(new HttpResponse({ body: response, status: err.status, url: err.url || undefined }));
      }),
      switchMap(event => {
        if (event.type === HttpEventType.Response) {
          // At this point, there are three cases:
          // 1) We have a successful server response that conforms to the BaseResponse structure
          // 2) The catchError operation has converted a network (event.status === 0) or server error (4XX, 5XX) to a valid BaseResponse with success === false
          // 3) We have a received a successful response from a different service e.g. AAI
          if (isBaseResponse(event.body)) {
            const response = event.body;
            if (!response.success && event.url && !this.isUrlExcludedFromGlobalError(event.url, request.context)) {
              const message = this.getErrorModalMessage(
                event.url,
                event.status,
                response.statusCode || null,
                response.messages?.[0]?.code || null
              );

              this.openErrorModal(message);

              // Remove this line to propagate error response to the caller
              return new Observable<HttpEvent<unknown>>();
            }
          }
        }
        return of(event);
      })
    );
  }
}
