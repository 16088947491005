import { createReducer, on } from '@ngrx/store';
import { initialState } from './contributors-dashboard.state';
import { ContributorsDashboardActions } from './contributors-dashboard.actions';

export const contributorsDashboardReducer = createReducer(
  initialState,
  on(ContributorsDashboardActions.incrementLoading, (state) => ({
    ...state,
    loadingCount: state.loadingCount + 1,
  })),
  on(ContributorsDashboardActions.decrementLoading, (state) => ({
    ...state,
    loadingCount: Math.max(0, state.loadingCount - 1),
  })),
  // Contributor Role
  on(ContributorsDashboardActions.loadContributorRole, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadContributorRoleSuccess, (state, { value }) => {
    return {
      ...state,
      contributorRole: value,
    };
  }),
  on(ContributorsDashboardActions.clearContributorRole, (state) => {
    return {
      ...state,
      contributorRole: null,
    };
  }),
  // EntityForReview
  on(ContributorsDashboardActions.loadEntityForReview, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadEntityForReviewSuccess, (state, { entityForReview }) => {
    return {
      ...state,
      entityForReview,
    };
  }),
  on(ContributorsDashboardActions.updateEntityForReview, (state, { value }) => {
    return {
      ...state,
      entityForReview: value,
    };
  }),
  on(ContributorsDashboardActions.clearEntityForReview, (state) => {
    return {
      ...state,
      entityForReview: null,
    };
  }),
  // EntitiesForReview
  on(ContributorsDashboardActions.loadEntitiesForReview, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadEntitiesForReviewSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      entitiesForReview: { results, total, from },
    };
  }),
  on(ContributorsDashboardActions.clearEntitiesForReview, (state) => {
    return {
      ...state,
      entitiesForReview: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Contributor
  on(ContributorsDashboardActions.loadContributor, (state) => {
    return {
      ...state,
      contributor: null,
    };
  }),
  on(ContributorsDashboardActions.loadContributorSuccess, (state, { contributor }) => {
    return {
      ...state,
      contributor,
    };
  }),
  on(ContributorsDashboardActions.updateContributor, (state, { value }) => {
    return {
      ...state,
      contributor: value,
    };
  }),
  on(ContributorsDashboardActions.clearContributor, (state) => {
    return {
      ...state,
      contributor: null,
    };
  }),
  // Contributors
  on(ContributorsDashboardActions.loadContributors, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadContributorsSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      contributors: { results, total, from },
    };
  }),
  on(ContributorsDashboardActions.clearContributors, (state) => {
    return {
      ...state,
      contributors: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Service
  on(ContributorsDashboardActions.loadService, (state) => {
    return {
      ...state,
      service: null,
    };
  }),
  on(ContributorsDashboardActions.loadServiceSuccess, (state, { service }) => {
    return {
      ...state,
      service,
    };
  }),
  on(ContributorsDashboardActions.updateService, (state, { value }) => {
    return {
      ...state,
      service: value,
    };
  }),
  on(ContributorsDashboardActions.clearService, (state) => {
    return {
      ...state,
      service: null,
    };
  }),
  // Services
  on(ContributorsDashboardActions.loadServices, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadServicesSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      services: { results, total, from },
    };
  }),
  on(ContributorsDashboardActions.clearServices, (state) => {
    return {
      ...state,
      services: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Datasource
  on(ContributorsDashboardActions.loadDatasource, (state) => {
    return {
      ...state,
      datasource: null,
    };
  }),
  on(ContributorsDashboardActions.loadDatasourceSuccess, (state, { datasource }) => {
    return {
      ...state,
      datasource,
    };
  }),
  on(ContributorsDashboardActions.updateDatasource, (state, { value }) => {
    return {
      ...state,
      datasource: value,
    };
  }),
  on(ContributorsDashboardActions.clearDatasource, (state) => {
    return {
      ...state,
      datasource: null,
    };
  }),
  // Datasources
  on(ContributorsDashboardActions.loadDatasources, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadDatasourcesSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      datasources: { results, total, from },
    };
  }),
  on(ContributorsDashboardActions.clearDatasources, (state) => {
    return {
      ...state,
      datasources: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Training Resource
  on(ContributorsDashboardActions.loadTrainingResource, (state) => {
    return {
      ...state,
      trainingResource: null,
    };
  }),
  on(ContributorsDashboardActions.loadTrainingResourceSuccess, (state, { trainingResource }) => {
    return {
      ...state,
      trainingResource,
    };
  }),
  on(ContributorsDashboardActions.updateTrainingResource, (state, { value }) => {
    return {
      ...state,
      trainingResource: value,
    };
  }),
  on(ContributorsDashboardActions.clearTrainingResource, (state) => {
    return {
      ...state,
      trainingResource: null,
    };
  }),
  // Training Resources
  on(ContributorsDashboardActions.loadTrainingResources, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadTrainingResourcesSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      trainingResources: { results, total, from },
    };
  }),
  on(ContributorsDashboardActions.clearTrainingResources, (state) => {
    return {
      ...state,
      trainingResources: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Interoperability Record
  on(ContributorsDashboardActions.loadInteroperabilityRecord, (state) => {
    return {
      ...state,
      interoperabilityRecord: null,
    };
  }),
  on(ContributorsDashboardActions.loadInteroperabilityRecordSuccess, (state, { interoperabilityRecord }) => {
    return {
      ...state,
      interoperabilityRecord,
    };
  }),
  on(ContributorsDashboardActions.updateInteroperabilityRecord, (state, { value }) => {
    return {
      ...state,
      interoperabilityRecord: value,
    };
  }),
  on(ContributorsDashboardActions.clearInteroperabilityRecord, (state) => {
    return {
      ...state,
      interoperabilityRecord: null,
    };
  }),
  // Interoperability Records
  on(ContributorsDashboardActions.loadInteroperabilityRecords, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadInteroperabilityRecordsSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      interoperabilityRecords: { results, total, from },
    };
  }),
  on(ContributorsDashboardActions.clearInteroperabilityRecords, (state) => {
    return {
      ...state,
      interoperabilityRecords: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Tool
  on(ContributorsDashboardActions.loadTool, (state) => {
    return {
      ...state,
      tool: null,
    };
  }),
  on(ContributorsDashboardActions.loadToolSuccess, (state, { tool }) => {
    return {
      ...state,
      tool,
    };
  }),
  on(ContributorsDashboardActions.updateTool, (state, { value }) => {
    return {
      ...state,
      tool: value,
    };
  }),
  on(ContributorsDashboardActions.clearTool, (state) => {
    return {
      ...state,
      tool: null,
    };
  }),
  // Tools
  on(ContributorsDashboardActions.loadTools, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadToolsSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      tools: { results, total, from },
    };
  }),
  on(ContributorsDashboardActions.clearTools, (state) => {
    return {
      ...state,
      tools: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Vocabulary
  on(ContributorsDashboardActions.loadVocabularies, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadVocabulariesCached, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadVocabulariesSuccess, (state, { vocabularyType, vocabulary }) => {
    return {
      ...state,
      vocabularies: { ...state.vocabularies, [vocabularyType]: vocabulary },
    };
  }),
  on(ContributorsDashboardActions.clearVocabularies, (state) => {
    return {
      ...state,
      vocabularies: {},
    };
  }),
  // Service Offers
  on(ContributorsDashboardActions.loadServiceOffers, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.loadServiceOffersSuccess, (state, { serviceOffers }) => {
    return {
      ...state,
      serviceOffers,
    };
  }),
  on(ContributorsDashboardActions.clearServiceOffers, (state) => {
    return {
      ...state,
      serviceOffers: [],
    };
  }),
  // Service Offer
  on(ContributorsDashboardActions.loadServiceOffer, (state, { value }) => {
    return {
      ...state,
      serviceOffer: value,
    };
  }),
  on(ContributorsDashboardActions.clearServiceOffer, (state) => {
    return {
      ...state,
      serviceOffer: null,
    };
  })
);
