<div class="flex flex-col gap-4">
  <div class="flex flex-col gap-3">
    <span class="text-lg font-semibold">{{ props.label }}</span>
    <div>
      <button class="w-1/2" mat-flat-button color="primary" (click)="f_input.click()">Choose File</button>
      <input
        #f_input
        type="file"
        hidden
        [attr.multiple]="props['multiple'] ? true : null"
        (change)="onFileChange($event)"
        [required]="props.required"
      />
    </div>
  </div>
  @if (formControl.value) {
  <div>
    <span class="text-base font-normal">File(s):</span>
    <mat-list>
      @for (file of formControl.value; track $index;) {
      <mat-list-item>{{ file.name }}</mat-list-item>
      }
    </mat-list>
  </div>
  }
</div>
