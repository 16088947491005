import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, of, withLatestFrom, filter, tap, finalize } from 'rxjs';
import { ProviderBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/service-interfaces/service.interface';
import { ContributorsDashboardActions } from './contributors-dashboard.actions';
import { ContributorsApiResponse } from '../../../features/dashboard/contributors-dashboard/interfaces/contrbutorsApiResponse.interface';
import { Store } from '@ngrx/store';
import * as ContributorsDashboardSelectors from '../../state/contributors-dashboard/contributors-dashboard.selectors';
import { TrainingResourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/training-resource-interfaces/training-resource.interface';
import { InteroperabilityRecordBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/interoperability-interfaces/interoperability.interface';
import { Vocabulary } from '../../../features/dashboard/shared/interfaces/vocabulary.interface';
import { DatasourceFull } from '../../../features/dashboard/contributors-dashboard/interfaces/datasource-interfaces/datasource.interface';
import { ToolBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/tool-interfaces/tool.interface';
import { ContributorService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/contributor.service';
import { ServiceService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/service.service';
import { DatasourceService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/datasource.service';
import { TrainingResourceService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/training-resource.service';
import { InteroperabilityService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/interoperability.service';
import { ToolService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/tool.service';
import { EntityForReview } from '../../../features/dashboard/contributors-dashboard/interfaces/review-entity-interfaces/review-entity.interface';
import { ReviewService } from '../../../features/dashboard/contributors-dashboard/shared/services/utils/review.service';
import { selectProfile } from '../auth/auth.selectors';
import { ContributorRole } from '../../../features/dashboard/contributors-dashboard/interfaces/contributorRole.interface';
import { ROLES } from '../../../roles.config';

@Injectable()
export class ContributorsDashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private reviewService: ReviewService,
    private contributorService: ContributorService,
    private serviceService: ServiceService,
    private datasourceService: DatasourceService,
    private trainingResourceService: TrainingResourceService,
    private interoperabilityService: InteroperabilityService,
    private toolService: ToolService
  ) {}

  loadEntityForReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadEntityForReview),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectEntityForReview)),
      filter(([_, cached]) => !cached),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap(([action]) =>
        this.reviewService.getEntityForReview(0, action.businessKey).pipe(
          map((data: { count: number; items: EntityForReview[]; page: number; size: number }) =>
            ContributorsDashboardActions.loadEntityForReviewSuccess({
              entityForReview: data.count ? data.items[0] : null,
            })
          ),
          finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
        )
      )
    )
  );

  loadEntitiesForReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadEntitiesForReview),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap((action) =>
        this.reviewService.getEntityForReview(action.from, action.query, action.taskType).pipe(
          filter((x) => !!x),
          map((data: { count: number; items: EntityForReview[]; page: number; size: number }) =>
            ContributorsDashboardActions.loadEntitiesForReviewSuccess({
              results: data.items ?? [],
              total: data.count,
              from: data.page,
            })
          ),
          finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
        )
      )
    )
  );

  loadContributor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadContributor),
      mergeMap((action) =>
        this.contributorService
          .getProvider(action.resourceId)
          .pipe(
            map((data: ProviderBundle) => ContributorsDashboardActions.loadContributorSuccess({ contributor: data }))
          )
      )
    )
  );

  loadContributorRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadContributorRole),
      mergeMap((action) => {
        return this.store.select(selectProfile).pipe(
          map((data: any) => {
            const contributorRole: ContributorRole = {
              admin: !!data.entitlements.includes(ROLES.ADMIN),
              ec: !!data.entitlements.includes(ROLES.EC_REVIEWER),
              eot: !!data.entitlements.includes(ROLES.EOT_ADMIN),
            };

            return ContributorsDashboardActions.loadContributorRoleSuccess({ value: contributorRole });
          })
        );
      })
    )
  );

  loadContributors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadContributors),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap((action) =>
        this.contributorService.getProviders(action.from, action.query, action.status, action.active).pipe(
          map((data: ContributorsApiResponse<ProviderBundle>) =>
            ContributorsDashboardActions.loadContributorsSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            })
          ),
          finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
        )
      )
    )
  );

  loadService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadService),
      mergeMap((action) =>
        this.serviceService.getService(action.resourceId).pipe(
          map((data: ServiceBundle) =>
            ContributorsDashboardActions.loadServiceSuccess({
              service: data,
            })
          )
        )
      )
    )
  );

  loadServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServices),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap((action) =>
        this.serviceService
          .getServices(action.contributor, action.from, action.query, action.status, action.active)
          .pipe(
            filter((x) => !!x),
            map((data: ContributorsApiResponse<ServiceBundle>) =>
              ContributorsDashboardActions.loadServicesSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              })
            ),
            finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
          )
      )
    )
  );

  loadDatasource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadDatasource),
      mergeMap((action) =>
        this.datasourceService
          .getDatasource(action.resourceId)
          .pipe(map((data: DatasourceFull) => ContributorsDashboardActions.loadDatasourceSuccess({ datasource: data })))
      )
    )
  );

  loadDatasources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadDatasources),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap((action) =>
        this.datasourceService
          .getDatasources(action.contributor, action.from, action.query, action.status, action.active)
          .pipe(
            filter((x) => !!x),
            map((data: ContributorsApiResponse<DatasourceFull>) =>
              ContributorsDashboardActions.loadDatasourcesSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              })
            ),
            finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
          )
      )
    )
  );

  loadTrainingResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadTrainingResource),
      mergeMap((action) =>
        this.trainingResourceService.getTrainingResource(action.resourceId).pipe(
          map((data: TrainingResourceBundle) =>
            ContributorsDashboardActions.loadTrainingResourceSuccess({
              trainingResource: data,
            })
          )
        )
      )
    )
  );

  loadTrainingResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadTrainingResources),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap((action) =>
        this.trainingResourceService
          .getTrainingResources(action.contributor, action.from, action.query, action.status, action.active)
          .pipe(
            filter((x) => !!x),
            map((data: ContributorsApiResponse<TrainingResourceBundle>) =>
              ContributorsDashboardActions.loadTrainingResourcesSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              })
            ),
            finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
          )
      )
    )
  );

  loadInteroperabilityRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadInteroperabilityRecord),
      mergeMap((action) =>
        this.interoperabilityService.getInteroperabilityRecord(action.resourceId).pipe(
          map((data: InteroperabilityRecordBundle) =>
            ContributorsDashboardActions.loadInteroperabilityRecordSuccess({
              interoperabilityRecord: data,
            })
          )
        )
      )
    )
  );

  loadInteroperabilityRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadInteroperabilityRecords),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap((action) =>
        this.interoperabilityService
          .getInteroperabilityRecords(action.contributor, action.from, action.query, action.status, action.active)
          .pipe(
            filter((x) => !!x),
            map((data: ContributorsApiResponse<InteroperabilityRecordBundle>) =>
              ContributorsDashboardActions.loadInteroperabilityRecordsSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              })
            ),
            finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
          )
      )
    )
  );

  loadTool$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadTool),
      mergeMap((action) => {
        // TODO fix above
        return this.toolService.getToolDetails(action.resourceId).pipe(
          map((data: ToolBundle) => {
            return ContributorsDashboardActions.loadToolSuccess({
              tool: data,
            });
          })
        );
      })
    )
  );

  loadTools$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadTools),
      tap(() => this.store.dispatch(ContributorsDashboardActions.incrementLoading())),
      mergeMap((action) =>
        this.toolService.getTools(action.from, action.query, action.contributor, action.status, action.active).pipe(
          filter((x) => !!x),
          map((data: ContributorsApiResponse<ToolBundle>) =>
            ContributorsDashboardActions.loadToolsSuccess({
              results: (data as any).content,
              total: data.total,
              from: data.from,
            })
          ),
          finalize(() => this.store.dispatch(ContributorsDashboardActions.decrementLoading()))
        )
      )
    )
  );

  loadVocabularies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadVocabularies),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectVocabularies)),
      mergeMap(([action, cachedData]) => {
        if (cachedData && cachedData.hasOwnProperty(action.vocabularyType)) {
          return of(ContributorsDashboardActions.loadVocabulariesCached());
        }
        return this.contributorService.getVocabularyByType(action.vocabularyType).pipe(
          map((vocabulary: Vocabulary[]) =>
            ContributorsDashboardActions.loadVocabulariesSuccess({
              vocabularyType: action.vocabularyType,
              vocabulary,
            })
          )
        );
      })
    )
  );

  loadServiceOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServiceOffers),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectServiceOffers)),
      mergeMap(([action, cachedData]) => {
        if (cachedData?.length) {
          return of(ContributorsDashboardActions.loadServiceOffersCached());
        }
        return this.contributorService
          .getUserOffers(action.serviceId)
          .pipe(map((serviceOffers) => ContributorsDashboardActions.loadServiceOffersSuccess({ serviceOffers })));
      })
    )
  );
}
