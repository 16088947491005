import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityForReview } from '../../../interfaces/review-entity-interfaces/review-entity.interface';
import { ContributorsDashboardEntityService } from '../contributors-dashboard-entity.service';
import { Store } from '@ngrx/store';
import { ContributorsDashboardHelperService } from './helpers/contributors-dashboard-helper.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReviewService extends ContributorsDashboardEntityService<EntityForReview> {
  entityForReview$: Observable<EntityForReview | null> = new Observable<EntityForReview | null>();

  constructor(store: Store, contributorsDashboardHelperService: ContributorsDashboardHelperService, http: HttpClient) {
    super(store, contributorsDashboardHelperService, http, 'review');
  }
}
