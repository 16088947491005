import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'formly-file-field',
  standalone: true,
  imports: [MatInputModule, MatListModule, MatFormFieldModule, FormlyModule, MatButtonModule],
  templateUrl: './file-field.component.html',
  styleUrl: './file-field.component.scss',
})
export class FileFieldComponent extends FieldType {
  onFileChange(event: any) {
    const files = event.target.files;
    this.formControl.setValue(files);
  }
}
