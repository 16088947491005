import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { injectTokenInterceptor } from './core/interceptors/inject-token.interceptor';
import { curateResourceDataInterceptor } from './core/interceptors/curate-resource-data.service';
import { orderFiltersInterceptor } from './core/interceptors/order-filters.interceptor';
import { ErrorHandlingInterceptor } from './core/interceptors/error-handling.interceptor';

import { provideOAuthClient } from 'angular-oauth2-oidc';

import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { reducers } from './core/state/reducers';
import { effects } from './core/state/effects';
import { FormlyModule } from '@ngx-formly/core';
import { UrlWrapperComponent } from './shared/formly/formly-custom-wrappers/url-wrapper/url-wrapper.component';
import { SubformWrapperPanelComponent } from './shared/formly/formly-custom-wrappers/subform-wrapper-panel/subform-wrapper-panel.component';
import { RepeatTypeComponent } from './shared/formly/formly-custom-wrappers/repeat-type/repeat-type.component';
import {
  EmailValidator,
  EmailValidatorMessage,
  MaxLengthValidatorMessage,
  MinLengthValidatorMessage,
  RequiredValidatorMessage,
  UrlValidator,
  UrlValidatorMessage,
} from './shared/formly/formly-validators';
import { StepperComponent } from './shared/formly/formly-custom-steppers/stepper/stepper.component';
import { FileFieldComponent } from './shared/formly/formly-custom-fields/file-field/file-field.component';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([injectTokenInterceptor, curateResourceDataInterceptor, orderFiltersInterceptor]),
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    provideOAuthClient(),
    provideStore(reducers),
    provideEffects(effects),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    importProvidersFrom([
      FormlyModule.forRoot({
        validators: [
          { name: 'url', validation: UrlValidator },
          {
            name: 'email',
            validation: EmailValidator,
          },
        ],
        validationMessages: [
          { name: 'required', message: RequiredValidatorMessage },
          { name: 'url', message: UrlValidatorMessage },
          { name: 'minLength', message: MinLengthValidatorMessage },
          { name: 'maxLength', message: MaxLengthValidatorMessage },
          { name: 'email', message: EmailValidatorMessage },
        ],
        types: [
          { name: 'stepper', component: StepperComponent },
          { name: 'repeat', component: RepeatTypeComponent },
          { name: 'file', component: FileFieldComponent },
        ],
        wrappers: [
          { name: 'subform-panel', component: SubformWrapperPanelComponent },
          { name: 'url', component: UrlWrapperComponent },
        ],
      }),
    ]),
  ],
};
