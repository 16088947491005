<div class="flex flex-col">
  <div class="grid grid-cols-12">
    <div class="col-span-11">
      <ng-container #fieldComponent> </ng-container>
    </div>
    <div class="flex items-center">
      <button mat-icon-button matBadge="!" [matBadgeHidden]="hideBadge()" color="primary" (click)="onAddComment()">
        <mat-icon fontIcon="comment"></mat-icon>
      </button>
    </div>
  </div>
  @if(showCommentSection()) {
  <div class="grid grid-cols-12">
    <div class="col-span-7"></div>
    <div class="col-span-4">
      <form [formGroup]="commentForm">
        <formly-form [model]="commentModel" [fields]="commentFields" [form]="commentForm"></formly-form>
      </form>
      @if(showFormButtons()) {
      <div class="flex flex-row justify-end gap-2">
        <button mat-icon-button color="primary" (click)="onConfirmComment()">
          <mat-icon fontIcon="done"></mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onDeleteComment()">
          <mat-icon fontIcon="delete"></mat-icon>
        </button>
      </div>
      }
    </div>
  </div>
  }
</div>
