import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function UrlValidator(control: AbstractControl): ValidationErrors | null {
  const urlRegex = /^(http(s)?:\/\/){1}[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  return !control.value || urlRegex.test(control.value) ? null : { url: true };
}

export function UrlValidatorMessage(error: any, field: FormlyFieldConfig): string {
  return `"${field?.formControl?.value}" is not a valid URL`;
}

export function RequiredValidatorMessage(error: any, field: FormlyFieldConfig): string {
  return `The "${field?.props?.label}" field is required`;
}

export function MinLengthValidatorMessage(error: any, field: FormlyFieldConfig): string {
  return `The "${field?.props?.label}" must be at least ${field?.props?.minLength} characters length.`;
}

export function MaxLengthValidatorMessage(error: any, field: FormlyFieldConfig): string {
  return `The "${field?.props?.label}" cannot exceed ${field?.props?.maxLength} characters length.`;
}

// TODO update regex
export function EmailValidator(control: AbstractControl): ValidationErrors | null {
  const urlRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/;
  return !control.value || urlRegex.test(control.value) ? null : { email: true };
}
export function EmailValidatorMessage(error: any, field: FormlyFieldConfig): string {
  return `"${field?.formControl?.value}" is not a valid email address`;
}
