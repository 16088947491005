<div class="grid grid-cols-12">
  <div class="col-span-11">
    <ng-container #fieldComponent></ng-container>
  </div>
  <div class="flex flex-col">
    <button mat-icon-button color="primary" (click)="onNavigateUrl()" title="Open in new tab">
      <mat-icon fontIcon="open_in_new"></mat-icon>
    </button>
  </div>
</div>
