import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { injectTokenInterceptor } from './core/interceptors/inject-token.interceptor';
import { curateResourceDataInterceptor } from './core/interceptors/curate-resource-data.service';
import { orderFiltersInterceptor } from './core/interceptors/order-filters.interceptor';
import { ErrorHandlingInterceptor } from './core/interceptors/error-handling.interceptor';

import { progressInterceptor } from 'ngx-progressbar/http';

import { provideOAuthClient } from 'angular-oauth2-oidc';

import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { reducers } from './core/state/reducers';
import { effects } from './core/state/effects';
import { FormlyModule } from '@ngx-formly/core';
import { UrlWrapperComponent } from './shared/formly/formly-custom-wrappers/url-wrapper/url-wrapper.component';
import { SubformWrapperPanelComponent } from './shared/formly/formly-custom-wrappers/subform-wrapper-panel/subform-wrapper-panel.component';
import { RepeatTypeComponent } from './shared/formly/formly-custom-wrappers/repeat-type/repeat-type.component';
import {
  EmailValidator,
  EmailValidatorMessage,
  MaxLengthValidatorMessage,
  MinLengthValidatorMessage,
  RequiredValidatorMessage,
  UrlValidator,
  UrlValidatorMessage,
} from './shared/formly/formly-validators';
import { StepperComponent } from './shared/formly/formly-custom-steppers/stepper/stepper.component';
import { FileFieldComponent } from './shared/formly/formly-custom-fields/file-field/file-field.component';
import { CommentWrapperComponent } from './shared/formly/formly-custom-wrappers/comment-wrapper/comment-wrapper.component';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { provideToastr } from 'ngx-toastr';

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([progressInterceptor, injectTokenInterceptor, curateResourceDataInterceptor, orderFiltersInterceptor]),
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    provideOAuthClient(),
    provideToastr({
      maxOpened: 3,
      autoDismiss: true,
      preventDuplicates: true,
      newestOnTop: true,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-top-right',
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      },
    }),
    provideStore(reducers),
    provideEffects(effects),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    importProvidersFrom([
      FormlyModule.forRoot({
        validators: [
          { name: 'url', validation: UrlValidator },
          {
            name: 'email',
            validation: EmailValidator,
          },
        ],
        validationMessages: [
          { name: 'required', message: RequiredValidatorMessage },
          { name: 'url', message: UrlValidatorMessage },
          { name: 'minLength', message: MinLengthValidatorMessage },
          { name: 'maxLength', message: MaxLengthValidatorMessage },
          { name: 'email', message: EmailValidatorMessage },
        ],
        types: [
          { name: 'stepper', component: StepperComponent },
          { name: 'repeat', component: RepeatTypeComponent },
          { name: 'file', component: FileFieldComponent },
        ],
        wrappers: [
          { name: 'subform-panel', component: SubformWrapperPanelComponent },
          { name: 'url', component: UrlWrapperComponent },
          { name: 'comment-wrapper', component: CommentWrapperComponent },
        ],
      }),
    ]),
    /**
     * Angular Material Datepicker
     *
     * https://material.angular.io/components/datepicker/overview
     */
    { provide: MAT_DATE_LOCALE, useValue: 'en-EN' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
};
