import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'app-url-wrapper',
  standalone: true,
  imports: [MatIconModule, FormlyModule, MatButtonModule],
  templateUrl: './url-wrapper.component.html',
  styleUrl: './url-wrapper.component.scss',
})
export class UrlWrapperComponent extends FieldWrapper {
  onNavigateUrl() {
    const values = Object.keys(this.form.value).map((key) => this.form.value[key]);
    const urlRegex = /^(http(s)?:\/\/){1}[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const url = values.find((val) => urlRegex.test(val));
    window.open(url?.toString(), '_blank');
  }
}
