import { Component, Inject } from '@angular/core';
import {
  MatDialogActions,
  MatDialogTitle,
  MatDialogContent,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-modal-generic-error',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogTitle,
    MatDialogContent,
    MatButtonModule,
  ],
  templateUrl: './modal-generic-error.component.html',
  styleUrl: './modal-generic-error.component.scss'
})
export class ModalGenericErrorComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
  ) {}

  reloadPage(): void {
    window.location.href = window.location.origin;
  }
}
