import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyService } from '../../formly.service';

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [MatStepperModule, MatButtonModule, FormlyModule],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent extends FieldType {
  constructor(private formlyService: FormlyService) {
    super();
  }
  onClick(action: string) {
    console.log(this.form);
    this.formlyService.setAction(action);
    this.formlyService.setAction(null);
  }
}
