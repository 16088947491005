import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../../../../../../core/interfaces/generic/api-response';
import { ContributorsApiResponse } from '../../../interfaces/contrbutorsApiResponse.interface';
import { ProviderBundle } from '../../../interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../interfaces/service-interfaces/service.interface';
import { contributorsDashboardEndpoints } from '../contributors-dashboard-endpoints.config';
import { DatasourceBundle } from '../../../interfaces/datasource-interfaces/datasource.interface';
import { TrainingResourceBundle } from '../../../interfaces/training-resource-interfaces/training-resource.interface';
import { InteroperabilityRecordBundle } from '../../../interfaces/interoperability-interfaces/interoperability.interface';
import { ServiceType } from '../../../interfaces/offer-interfaces/serviceType.interface';
import { Offer } from '../../../interfaces/offer-interfaces/offer.interface';
import { VocabularyType } from '../../../../shared/interfaces/vocabulary.enum';
import { Vocabulary } from '../../../../shared/interfaces/vocabulary.interface';

@Injectable({
  providedIn: 'root',
})
export class ContributorsDashboardService {
  constructor(private http: HttpClient) {}

  getVocabularyByType(type: VocabularyType) {
    const url = `${contributorsDashboardEndpoints.vocabulary.baseUrl}${contributorsDashboardEndpoints.vocabulary.endpoints.getByType}/?type=${type}`;
    return this.http.get<ApiResponse<Vocabulary[]>>(url).pipe(map((data) => data.result));
  }

  getProviders(from?: number, query?: string): Observable<ContributorsApiResponse<ProviderBundle[]>> {
    let url = `${contributorsDashboardEndpoints.providers.baseUrl}${contributorsDashboardEndpoints.providers.endpoints.getAll}?`;
    if (from) url = `${url}from=${from}&`;
    if (query) url = `${url}query=${query}&`;
    return this.http.get<ApiResponse<ContributorsApiResponse<ProviderBundle[]>>>(url).pipe(map((item) => item.result));
  }

  validateProvider(providerBundle: ProviderBundle): any {
    const url = `${contributorsDashboardEndpoints.providers.baseUrl}${contributorsDashboardEndpoints.providers.endpoints.validate}`;
    return this.http.post(url, providerBundle);
  }

  updateProvider(providerBundle: ProviderBundle): any {
    const url = `${contributorsDashboardEndpoints.providers.baseUrl}${contributorsDashboardEndpoints.providers.endpoints.update}`;
    return this.http.put(url, providerBundle);
  }

  getServices(
    contributorId: string,
    from?: number,
    query?: string
  ): Observable<ContributorsApiResponse<ServiceBundle[]>> {
    let url = `${contributorsDashboardEndpoints.services.baseUrl}${contributorsDashboardEndpoints.services.endpoints.getAll}`;

    if (contributorId) {
      const id = encodeURIComponent(contributorId);
      url = `${url}?query=${id}&quantity=50`;
    } else {
      url = `${url}?`;
      if (from) url = `${url}from=${from}&`;
      if (query) url = `${url}query=${query}&`;
    }

    return this.http.get<ApiResponse<ContributorsApiResponse<ServiceBundle[]>>>(url).pipe(map((item) => item.result));
  }

  validateService(serviceBundle: ServiceBundle): any {
    const url = `${contributorsDashboardEndpoints.services.baseUrl}${contributorsDashboardEndpoints.services.endpoints.validate}`;
    return this.http.post(url, serviceBundle);
  }

  updateService(serviceBundle: ServiceBundle): any {
    const url = `${contributorsDashboardEndpoints.services.baseUrl}`;
    return this.http.put(url, serviceBundle);
  }

  deleteService(serviceId: string): any {
    const url = `${contributorsDashboardEndpoints.services.baseUrl}/${serviceId}`;
    return this.http.delete(url);
  }

  getDatasources(
    contributorId: string,
    serviceId: string,
    from?: number,
    query?: string
  ): Observable<ContributorsApiResponse<DatasourceBundle[]>> {
    let url = `${contributorsDashboardEndpoints.datasources.baseUrl}${contributorsDashboardEndpoints.datasources.endpoints.getAll}`;

    if (contributorId || serviceId) {
      const id = contributorId ? encodeURIComponent(contributorId) : encodeURIComponent(serviceId);
      url = `${url}?query=${id}&quantity=50`;
    } else {
      url = `${url}?`;
      if (from) url = `${url}from=${from}&`;
      if (query) url = `${url}query=${query}&`;
    }

    return this.http
      .get<ApiResponse<ContributorsApiResponse<DatasourceBundle[]>>>(url)
      .pipe(map((item) => item.result));
  }

  validateDatasource(datasourceBundle: DatasourceBundle): any {
    const url = `${contributorsDashboardEndpoints.datasources.baseUrl}${contributorsDashboardEndpoints.datasources.endpoints.validate}`;
    return this.http.post(url, datasourceBundle);
  }

  updateDatasource(datasourceBundle: DatasourceBundle): any {
    const url = `${contributorsDashboardEndpoints.datasources.baseUrl}`;
    return this.http.put(url, datasourceBundle);
  }

  deleteDatasource(datasourceId: string): any {
    const url = `${contributorsDashboardEndpoints.datasources.baseUrl}/${datasourceId}`;
    return this.http.delete(url);
  }

  getTrainingResources(
    contributorId: string,
    from?: number,
    query?: string
  ): Observable<ContributorsApiResponse<TrainingResourceBundle[]>> {
    let url = `${contributorsDashboardEndpoints.trainingResources.baseUrl}${contributorsDashboardEndpoints.trainingResources.endpoints.getAll}`;

    if (contributorId) {
      const id = encodeURIComponent(contributorId);
      url = `${url}?query=${id}&quantity=50`;
    } else {
      url = `${url}?`;
      if (from) url = `${url}from=${from}&`;
      if (query) url = `${url}query=${query}&`;
    }

    return this.http
      .get<ApiResponse<ContributorsApiResponse<TrainingResourceBundle[]>>>(url)
      .pipe(map((item) => item.result));
  }

  validateTrainingResource(trainingResourceBundle: TrainingResourceBundle): any {
    const url = `${contributorsDashboardEndpoints.trainingResources.baseUrl}${contributorsDashboardEndpoints.trainingResources.endpoints.validate}`;
    return this.http.post(url, trainingResourceBundle);
  }

  updateTrainingResource(trainingResourceBundle: TrainingResourceBundle): any {
    const url = `${contributorsDashboardEndpoints.trainingResources.baseUrl}`;
    return this.http.put(url, trainingResourceBundle);
  }

  deleteTrainingResource(trainingResourceId: string): any {
    const url = `${contributorsDashboardEndpoints.trainingResources.baseUrl}/${trainingResourceId}`;
    return this.http.delete(url);
  }

  getInteroperabilityGuidelines(
    contributorId: string,
    from?: number,
    query?: string
  ): Observable<ContributorsApiResponse<InteroperabilityRecordBundle[]>> {
    let url = `${contributorsDashboardEndpoints.interoperabilityRecords.baseUrl}${contributorsDashboardEndpoints.interoperabilityRecords.endpoints.getAll}`;

    if (contributorId) {
      const id = encodeURIComponent(contributorId);
      url = `${url}?query=${id}&quantity=50`;
    } else {
      url = `${url}?`;
      if (from) url = `${url}from=${from}&`;
      if (query) url = `${url}query=${query}&`;
    }

    return this.http
      .get<ApiResponse<ContributorsApiResponse<InteroperabilityRecordBundle[]>>>(url)
      .pipe(map((item) => item.result));
  }

  validateInteroperabilityRecord(interoperabilityRecordBundle: InteroperabilityRecordBundle): any {
    const url = `${contributorsDashboardEndpoints.interoperabilityRecords.baseUrl}${contributorsDashboardEndpoints.interoperabilityRecords.endpoints.validate}`;
    return this.http.post(url, interoperabilityRecordBundle);
  }

  updateInteroperabilityRecord(interoperabilityRecordBundle: InteroperabilityRecordBundle): any {
    const url = `${contributorsDashboardEndpoints.interoperabilityRecords.baseUrl}`;
    return this.http.put(url, interoperabilityRecordBundle);
  }

  deleteInteroperabilityRecord(interoperabilityRecordId: string): any {
    const url = `${contributorsDashboardEndpoints.interoperabilityRecords.baseUrl}/${interoperabilityRecordId}`;
    return this.http.delete(url);
  }

  getOfferParameters(): Observable<ServiceType> {
    const url = `${contributorsDashboardEndpoints.adminOffers.baseUrl}${contributorsDashboardEndpoints.adminOffers.endpoints.getParameters}`;
    return this.http.get<ApiResponse<ServiceType>>(url).pipe(map((data) => data.result));
  }

  getUserOffers(resourceId: string): Observable<Offer[]> {
    const id = encodeURIComponent(resourceId);
    const url = `${contributorsDashboardEndpoints.adminOffers.baseUrl}${contributorsDashboardEndpoints.adminOffers.endpoints.getOffers}/${id}`;
    return this.http.get<ApiResponse<Offer[]>>(url).pipe(map((data) => data.result));
  }
}
