import { createReducer, on } from '@ngrx/store';
import { initialState } from './contributors-dashboard.state';
import { ContributorsDashboardActions } from './contributors-dashboard.actions';

export const contributorsDashboardReducer = createReducer(
  initialState,
  on(ContributorsDashboardActions.incrementLoading, (state) => ({
    ...state,
    loadingCount: state.loadingCount + 1,
  })),
  on(ContributorsDashboardActions.decrementLoading, (state) => ({
    ...state,
    loadingCount: Math.max(0, state.loadingCount - 1),
  })),
  // Contributor Mode
  on(ContributorsDashboardActions.loadContributorMode, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.updateContributorMode, (state, { value }) => {
    return {
      ...state,
      contributorMode: value,
    };
  }),
  on(ContributorsDashboardActions.clearContributorMode, (state) => {
    return {
      ...state,
      contributorMode: false,
    };
  }),
  // Contributor
  on(ContributorsDashboardActions.loadContributor, (state, { value }) => {
    return {
      ...state,
      contributor: value,
    };
  }),
  on(ContributorsDashboardActions.updateContributor, (state, { value }) => {
    return {
      ...state,
      contributor: value,
    };
  }),
  on(ContributorsDashboardActions.clearContributor, (state) => {
    return {
      ...state,
      contributor: null,
    };
  }),
  // Contributors
  on(ContributorsDashboardActions.loadContributors, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadContributorsSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      contributors: { results, total, from },
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearContributors, (state) => {
    return {
      ...state,
      contributors: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Service
  on(ContributorsDashboardActions.loadService, (state, { value }) => {
    return {
      ...state,
      service: value,
    };
  }),
  on(ContributorsDashboardActions.updateService, (state, { value }) => {
    return {
      ...state,
      service: value,
    };
  }),
  on(ContributorsDashboardActions.clearService, (state) => {
    return {
      ...state,
      service: null,
    };
  }),
  // Services
  on(ContributorsDashboardActions.loadServices, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadServicesSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      services: { results, total, from },
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearServices, (state) => {
    return {
      ...state,
      services: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Datasource
  on(ContributorsDashboardActions.loadServiceDatasource, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.updateServiceDatasource, (state, { value }) => {
    return {
      ...state,
      serviceDatasource: value,
    };
  }),
  on(ContributorsDashboardActions.clearServiceDatasource, (state) => {
    return {
      ...state,
      serviceDatasource: null,
    };
  }),
  on(ContributorsDashboardActions.loadDatasource, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.updateDatasource, (state, { value }) => {
    return {
      ...state,
      datasource: value,
    };
  }),
  on(ContributorsDashboardActions.clearDatasource, (state) => {
    return {
      ...state,
      datasource: null,
    };
  }),
  // Datasources
  on(ContributorsDashboardActions.loadDatasources, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadDatasourcesSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      datasources: { results, total, from },
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearDatasources, (state) => {
    return {
      ...state,
      datasources: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Training Resource
  on(ContributorsDashboardActions.loadTrainingResource, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.updateTrainingResource, (state, { value }) => {
    return {
      ...state,
      trainingResource: value,
    };
  }),
  on(ContributorsDashboardActions.clearTrainingResource, (state) => {
    return {
      ...state,
      trainingResource: null,
    };
  }),
  // Training Resources
  on(ContributorsDashboardActions.loadTrainingResources, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadTrainingResourcesSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      trainingResources: { results, total, from },
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearTrainingResources, (state) => {
    return {
      ...state,
      trainingResources: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Interoperability Record
  on(ContributorsDashboardActions.loadInteroperabilityRecord, (state) => {
    return {
      ...state,
    };
  }),
  on(ContributorsDashboardActions.updateInteroperabilityRecord, (state, { value }) => {
    return {
      ...state,
      interoperabilityRecord: value,
    };
  }),
  on(ContributorsDashboardActions.clearInteroperabilityRecord, (state) => {
    return {
      ...state,
      interoperabilityRecord: null,
    };
  }),
  // Interoperability Records
  on(ContributorsDashboardActions.loadInteroperabilityRecords, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadInteroperabilityRecordsSuccess, (state, { results, total, from }) => {
    return {
      ...state,
      interoperabilityRecords: { results, total, from },
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearInteroperabilityRecords, (state) => {
    return {
      ...state,
      interoperabilityRecords: { results: [], total: 0, from: 0, to: 0 },
    };
  }),
  // Vocabulary
  on(ContributorsDashboardActions.loadVocabularies, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadVocabulariesCached, (state) => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.loadVocabulariesSuccess, (state, { vocabularyType, vocabulary }) => {
    return {
      ...state,
      vocabularies: { ...state.vocabularies, [vocabularyType]: vocabulary },
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearVocabularies, (state) => {
    return {
      ...state,
      vocabularies: {},
    };
  }),
  // Service Type
  on(ContributorsDashboardActions.loadServiceType, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadServiceTypeSuccess, (state, { serviceType }) => {
    return {
      ...state,
      serviceType,
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearServiceType, (state) => {
    return {
      ...state,
      serviceType: {},
    };
  }),
  // Service Offers
  on(ContributorsDashboardActions.loadServiceOffers, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ContributorsDashboardActions.loadServiceOffersSuccess, (state, { serviceOffers }) => {
    return {
      ...state,
      serviceOffers,
      loading: false,
    };
  }),
  on(ContributorsDashboardActions.clearServiceOffers, (state) => {
    return {
      ...state,
      serviceOffers: [],
    };
  }),
  // Sevice Offer
  on(ContributorsDashboardActions.loadServiceOffer, (state, { value }) => {
    return {
      ...state,
      serviceOffer: value,
    };
  }),
  on(ContributorsDashboardActions.clearServiceOffer, (state) => {
    return {
      ...state,
      serviceOffer: null,
    };
  })
);
