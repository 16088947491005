import { environment } from '../environments/environment';

export const errorCodes: {
    [key: string]: {
        statusCode?: number,
        serviceStatusCode?: number,
        messageCode?: string,
        message: string,
    }[]
} = {
    [`${environment.apiUrl}/action/catalogue`]: [
        { serviceStatusCode: 404, message: 'Resource not found' },
    ],
    [`${environment.adminApiUrl}/oms`]: [
        { messageCode: 'ΟMS-ERROR-0001', message: 'Internal Server Error.'},
        { messageCode: 'ΟMS-ERROR-0002', message: 'Forbidden.'},
        { messageCode: 'ΟMS-ERROR-0003', message: 'Bad Request.'},
        { messageCode: 'ΟMS-OFFER-0001', message: 'Offer not found!'},
        { messageCode: 'ΟMS-ORDER-0001', message: 'Order not found!'},
        { messageCode: 'ΟMS-ORDER-0002', message: 'There are conflicting orders for this project/service.'},
        { messageCode: 'ΟMS-FEEDBACK-0001', message: 'Feedback not found!'},
        { messageCode: 'ΟMS-ERROR-0001', message: 'Release request not found!'},
    ],
    [`${environment.adminApiUrl}/action/credits`]: [
        { messageCode: 'CMS-ERROR-0001', message: 'Internal Server Error.'},
        { messageCode: 'CMS-ERROR-0002', message: 'Forbidden.'},
        { messageCode: 'CMS-ERROR-0003', message: 'Setting does not exist.'},
        { messageCode: 'CMS-ERROR-0004', message: 'HTTP Header is required.'},
        { messageCode: 'CMS-ERROR-0005', message: 'Expected a single database result.'},
        { messageCode: 'VALIDATION-0001', message: 'Parameter is required.'},
        { messageCode: 'VALIDATION-0002', message: 'Parameter value is not valid.'},
        { messageCode: 'VALIDATION-0005', message: 'Collection items must be unique.'},
        { messageCode: 'VALIDATION-0008', message: 'The value does not exist in the enumeration.'},
        { messageCode: 'CMS-CREDITS-0001', message: 'Account does not exist.'},
        { messageCode: 'CMS-CREDITS-0002', message: 'Project does not exist.'},
        { messageCode: 'CMS-CREDITS-0003', message: 'Wallet does not exist.'},
        { messageCode: 'CMS-CREDITS-0004', message: 'Subscription does not exist.'},
        { messageCode: 'CMS-CREDITS-0005', message: 'Transaction does not exist.'},
        { messageCode: 'CMS-CREDITS-0006', message: 'Idempotent key already exists.'},
        { messageCode: 'CMS-CREDITS-0007', message: 'No transaction with the specified idempotent key exists.'},
        { messageCode: 'CMS-CREDITS-0008', message: 'Not enough credits to execute the transaction.'},
        { messageCode: 'CMS-CREDITS-0009', message: 'Refund funds must be equal to the transaction funds.'},
        { messageCode: 'CMS-CREDITS-0010', message: 'Refund is not allowed for the type of transaction.'},
        { messageCode: 'CMS-CREDITS-0011', message: 'Transaction to refund was not found.'},
        { messageCode: 'CMS-CREDITS-0012', message: 'A refund already exists for the transaction.'},
        { messageCode: 'CMS-CREDITS-0013', message: 'Refund credited account must be the same with the debited account of the target transaction.'},
        { messageCode: 'CMS-CREDITS-0014', message: 'Refund wallet must be the same with the wallet of the target transaction.'},
        { messageCode: 'CMS-CREDITS-0015', message: 'The requested operation is not allowed for the current state of the subscription.'},
        { messageCode: 'CMS-CREDITS-0016', message: 'The request PayIn does not exist.'},
        { messageCode: 'CMS-CREDITS-0017', message: 'No more requests can be submitted for the selected project.'},
        { messageCode: 'CMS-CREDITS-0018', message: 'The requested operation is not allowed for the status of the PayIn request.'},
        { messageCode: 'CMS-CREDITS-0019', message: 'The requested operation is not supported by the project.'},
        { messageCode: 'CMS-QUOTA-0001', message: 'Account does not exist.'},
        { messageCode: 'CMS-QUOTA-0002', message: 'Service registration does not exist.'},
        { messageCode: 'CMS-QUOTA-0003', message: 'Installation registration does not exist.'},
        { messageCode: 'CMS-QUOTA-0004', message: 'Resource type does not exist.'},
        { messageCode: 'CMS-QUOTA-0005', message: 'Project does not exist.'},
        { messageCode: 'CMS-QUOTA-0006', message: 'Service allocation does not exist.'},
        { messageCode: 'CMS-QUOTA-0007', message: 'Service allocation start date is not valid. Start date must be equal or after today and inside the project interval.'},
        { messageCode: 'CMS-QUOTA-0008', message: 'Service allocation end date is not valid. End date must be equal or before the project end date.'},
        { messageCode: 'CMS-QUOTA-0009', message: 'The requested operation is not allowed for the status of the service allocation.'},
        { messageCode: 'CMS-QUOTA-0010', message: 'The requested operation is not allowed for the current state of the project.'},
        { messageCode: 'CMS-QUOTA-0011', message: 'The project end date is not valid. The end date must be equal or greater than the start and the current dates.'},
        { messageCode: 'CMS-RESTCLIENT-0001', message: 'REST Client operation has failed.'},
        { messageCode: 'CMS-MESSAGING-0001', message: 'Deserialized request does not match the expected type.'},
        { messageCode: 'CMS-MESSAGING-0002', message: 'Message validation has failed.'},
        { messageCode: 'CMS-MESSAGING-0003', message: 'Message processing has failed.'},
    ],
    [`${environment.adminApiUrl}/action/admin/profile`]: [
        { messageCode: 'PROFILE-ERROR-0001', message: 'Internal Server Error.'},
        { messageCode: 'PROFILE-ERROR-0002', message: 'Forbidden.'},
        { messageCode: 'PROFILE-ERROR-0003', message: 'Expected a single database result.'},
        { messageCode: 'VALIDATION-0001', message: 'Parameter is required.'},
        { messageCode: 'VALIDATION-0004', message: 'Parameter size is not valid.'},
        { messageCode: 'VALIDATION-0003', message: 'Parameter format is not valid.'},
        { messageCode: 'VALIDATION-0002', message: 'Parameter value is not valid.'},
        { messageCode: 'VALIDATION-0007', message: 'Parameter value has already been set.'},
        { messageCode: 'VALIDATION-0009', message: 'Values in the collection are incompatible.'},
        { messageCode: 'PROFILE-ERROR-1001', message: 'The profile failed to activate.'},
        { messageCode: 'PROFILE-ERROR-1002', message: 'The profile failed to activate.'},
        { messageCode: 'PROFILE-ERROR-1003', message: 'The profile does not exist.'},
        { messageCode: 'PROFILE-ERROR-1004', message: 'The profile state is not consistent.'},
        { messageCode: 'PROFILE-ERROR-1005', message: 'The project does not exist.'},
        { messageCode: 'PROFILE-ERROR-1006', message: 'A project with the same name already exists.'},
        { messageCode: 'PROFILE-ERROR-1007', message: 'The requested operation is not supported by the project.'},
        { messageCode: 'PROFILE-ERROR-1008', message: 'User cannot create more group projects.'},
        { messageCode: 'PROFILE-ERROR-1009', message: 'User cannot join more group projects.'},
        { messageCode: 'PROFILE-ERROR-1010', message: 'Cannot remove the owner of a project.'},
        { messageCode: 'PROFILE-ERROR-1011', message: 'The requested operation is not allowed for the status of the PayIn request.'},
        { messageCode: 'PROFILE-ERROR-1012', message: 'The entitlement request does not exist.'},
        { messageCode: 'PROFILE-ERROR-1013', message: 'A request for the specified entitlement already exists.'},
        { messageCode: 'PROFILE-ERROR-1014', message: 'The AAI profile does not exist.'},
        { messageCode: 'PROFILE-ERROR-1015', message: 'Project member was not found.'},
        { messageCode: 'PROFILE-ERROR-1016', message: 'User is already a member of the project.'},
        { messageCode: 'PROFILE-ERROR-1017', message: 'The project end date is not valid. The end date must be equal or greater than the start and the current dates.'},
        { messageCode: 'PROFILE-ERROR-1018', message: 'A pending project invite already exists for the specified member and project.'},
        { messageCode: 'PROFILE-ERROR-1019', message: 'Project invite was not found.'},
        { messageCode: 'PROFILE-ERROR-1020', message: 'Project invite has been expired.'},
        { messageCode: 'PROFILE-ERROR-1021', message: 'Cannot remove the owner of an organization.'},
        { messageCode: 'PROFILE-ERROR-1022', message: 'An organization already exists for the specified PID.'},
        { messageCode: 'PROFILE-ERROR-1023', message: 'An organization already exists for the specified name.'},
        { messageCode: 'PROFILE-ERROR-1024', message: 'The organization does not exist.'},
        { messageCode: 'PROFILE-ERROR-1025', message: 'Organization member was not found.'},
        { messageCode: 'PROFILE-ERROR-1026', message: 'User cannot create more organizations.'},
        { messageCode: 'PROFILE-ERROR-1027', message: 'User cannot join more organizations.'},
        { messageCode: 'PROFILE-ERROR-1028', message: 'User is already a member of the organization.'},
        { messageCode: 'PROFILE-ERROR-1029', message: 'Cannot change the roles of the organization owner.'},
        { messageCode: 'PROFILE-ERROR-1030', message: 'Cannot resolve access policy level.'},
        { messageCode: 'PROFILE-RESTCLIENT-2001', message: 'REST Client operation has failed.'}
    ],
    [`${environment.adminApiUrl}/action`]: [
        { messageCode: 'ADMIN-ERROR-0001', message: 'Internal Server Error.' },
        { messageCode: 'ADMIN-ERROR-0002', message: 'Forbidden.' },
        { messageCode: 'ADMIN-ERROR-0003', message: 'Parameter required.' },
        { messageCode: 'ADMIN-ERROR-0004', message: 'Parameter value not valid.' },
        { messageCode: 'ADMIN-PROFILE-0001', message: 'Personal project not found.' },
        { messageCode: 'ADMIN-CURATION_HUB-1001', message: 'Resource not found.' },
        { messageCode: 'ADMIN-CURATION_HUB-1002', message: 'Resource status cannot be updated to the requested value.' },
        { messageCode: 'ADMIN-RESTCLIENT-0001', message: 'REST Client operation failed.' },
    ],
};
