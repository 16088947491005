import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-subform-wrapper-panel',
  standalone: true,
  imports: [],
  templateUrl: './subform-wrapper-panel.component.html',
  styleUrl: './subform-wrapper-panel.component.scss',
})
export class SubformWrapperPanelComponent extends FieldWrapper {}
