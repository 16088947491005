import { Component, signal } from '@angular/core';
import { ReactiveFormsModule, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { FieldWrapper, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyService } from '../../formly.service';

@Component({
  selector: 'app-comment-wrapper',
  standalone: true,
  imports: [
    MatIconModule,
    MatBadgeModule,
    FormlyModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
  ],
  templateUrl: './comment-wrapper.component.html',
  styleUrl: './comment-wrapper.component.scss',
})
export class CommentWrapperComponent extends FieldWrapper {
  commentForm: FormGroup = new FormGroup({});
  commentFields: FormlyFieldConfig[] = [
    {
      key: 'comment',
      type: 'textarea',
      props: {
        label: 'Comment',
        autosize: true,
        rows: 5,
      },
    },
  ];
  commentModel: any = {};
  showCommentSection = signal(false);
  hideBadge = signal(true);
  showFormButtons = signal(true);

  constructor(private formlyService: FormlyService) {
    super();
  }

  ngOnInit() {
    if (this.props) {
      if (this.props['comment']) {
        this.commentModel.comment = this.props['comment'];
        this.hideBadge.set(false);
      }
      this.showFormButtons.set(!this.props['reviewComment']);
    }
  }

  onAddComment() {
    this.showCommentSection.set(!this.showCommentSection());
    if (this.props && this.props['reviewComment'] && this.showCommentSection()) {
      setTimeout(() => {
        this.commentForm.disable();
      });
    }
  }

  onConfirmComment() {
    if (this.commentModel.comment) {
      this.hideBadge.set(false);
      this.showCommentSection.set(false);
      this.formlyService.setComment({
        parent: this.field?.parent!.key!.toString(),
        key: this.field?.fieldGroup![0].key!.toString(),
        comment: this.commentModel.comment,
      });
    }
  }

  // Is used parent and key due to the fact that we use
  // wizards.
  // TODO improve for all the cases
  onDeleteComment() {
    this.commentModel = { comment: '' };
    this.formlyService.setComment({
      parent: this.field?.parent!.key!.toString(),
      key: this.field?.fieldGroup![0].key!.toString(),
      comment: '',
    });
    this.hideBadge.set(true);
    this.showCommentSection.set(false);
  }
}
