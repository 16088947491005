import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ContributorsDashboardService } from '../../../features/dashboard/contributors-dashboard/shared/services/http/contributors-dashboard.service';
import { mergeMap, map, of, withLatestFrom } from 'rxjs';
import { ProviderBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/provider-interfaces/provider.interface';
import { ServiceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/service-interfaces/service.interface';
import { ContributorsDashboardActions } from './contributors-dashboard.actions';
import { ContributorsApiResponse } from '../../../features/dashboard/contributors-dashboard/interfaces/contrbutorsApiResponse.interface';
import { Store } from '@ngrx/store';
import * as ContributorsDashboardSelectors from '../../state/contributors-dashboard/contributors-dashboard.selectors';
import { DatasourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/datasource-interfaces/datasource.interface';
import { TrainingResourceBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/training-resource-interfaces/training-resource.interface';
import { InteroperabilityRecordBundle } from '../../../features/dashboard/contributors-dashboard/interfaces/interoperability-interfaces/interoperability.interface';
import { Vocabulary } from '../../../features/dashboard/shared/interfaces/vocabulary.interface';

@Injectable()
export class ContributorsDashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private contributorsDashboardService: ContributorsDashboardService
  ) {}

  loadContributors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadContributors),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.contributorsDashboardService.getProviders(action.from, action.query).pipe(
          map((data: ContributorsApiResponse<ProviderBundle[]>) => {
            this.store.dispatch(ContributorsDashboardActions.decrementLoading());
            return ContributorsDashboardActions.loadContributorsSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            });
          })
        );
      })
    )
  );

  loadServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServices),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.contributorsDashboardService.getServices(action.contributor, action.from, action.query).pipe(
          map((data: ContributorsApiResponse<ServiceBundle[]>) => {
            this.store.dispatch(ContributorsDashboardActions.decrementLoading());
            return ContributorsDashboardActions.loadServicesSuccess({
              results: data.results,
              total: data.total,
              from: data.from,
            });
          })
        );
      })
    )
  );

  loadDatasources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadDatasources),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.contributorsDashboardService
          .getDatasources(action.contributor, action.service, action.from, action.query)
          .pipe(
            map((data: ContributorsApiResponse<DatasourceBundle[]>) => {
              this.store.dispatch(ContributorsDashboardActions.decrementLoading());
              return ContributorsDashboardActions.loadDatasourcesSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              });
            })
          );
      })
    )
  );

  loadTrainingResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadTrainingResources),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.contributorsDashboardService
          .getTrainingResources(action.contributor, action.from, action.query)
          .pipe(
            map((data: ContributorsApiResponse<TrainingResourceBundle[]>) => {
              this.store.dispatch(ContributorsDashboardActions.decrementLoading());
              return ContributorsDashboardActions.loadTrainingResourcesSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              });
            })
          );
      })
    )
  );

  loadInteroperabilityRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadInteroperabilityRecords),
      mergeMap((action) => {
        this.store.dispatch(ContributorsDashboardActions.incrementLoading());
        return this.contributorsDashboardService
          .getInteroperabilityGuidelines(action.contributor, action.from, action.query)
          .pipe(
            map((data: ContributorsApiResponse<InteroperabilityRecordBundle[]>) => {
              this.store.dispatch(ContributorsDashboardActions.decrementLoading());
              return ContributorsDashboardActions.loadInteroperabilityRecordsSuccess({
                results: data.results,
                total: data.total,
                from: data.from,
              });
            })
          );
      })
    )
  );

  loadVocabularies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadVocabularies),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectVocabularies)),
      mergeMap(([action, cachedData]) => {
        if (cachedData && cachedData.hasOwnProperty(action.vocabularyType)) {
          return of(ContributorsDashboardActions.loadVocabulariesCached());
        }
        return this.contributorsDashboardService.getVocabularyByType(action.vocabularyType).pipe(
          map((vocabulary: Vocabulary[]) =>
            ContributorsDashboardActions.loadVocabulariesSuccess({
              vocabularyType: action.vocabularyType,
              vocabulary,
            })
          )
        );
      })
    )
  );

  loadServiceType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServiceType),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectServiceType)),
      mergeMap(([action, cachedData]) => {
        if (cachedData) {
          return of(ContributorsDashboardActions.loadServiceTypeCached());
        }
        return this.contributorsDashboardService
          .getOfferParameters()
          .pipe(map((serviceType) => ContributorsDashboardActions.loadServiceTypeSuccess({ serviceType })));
      })
    )
  );

  loadServiceOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContributorsDashboardActions.loadServiceOffers),
      withLatestFrom(this.store.select(ContributorsDashboardSelectors.selectServiceOffers)),
      mergeMap(([action, cachedData]) => {
        if (cachedData?.length) {
          return of(ContributorsDashboardActions.loadServiceOffersCached());
        }
        return this.contributorsDashboardService
          .getUserOffers(action.serviceId)
          .pipe(map((serviceOffers) => ContributorsDashboardActions.loadServiceOffersSuccess({ serviceOffers })));
      })
    )
  );
}
