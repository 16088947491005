<mat-stepper orientation="vertical" #stepper>
  @for (step of field.fieldGroup; track $index) {
  <mat-step>
    <ng-template matStepLabel>{{ step.props?.label }}</ng-template>
    <div class="mt-4">
      <formly-field [field]="step"></formly-field>
      @if(!$first) {
      <button mat-button matStepperPrevious>Previous</button>
      } @if(!$last){
      <button mat-button matStepperNext>Next</button>
      }
    </div>
  </mat-step>
  }
</mat-stepper>
@if (props['buttons']) {
<div class="flex flex-row justify-between gap-4 p-4">
  <div class="flex flex-row gap-4">
    @for (button of props['buttons']['leftSetButtons']; track $index) {
    <button
      mat-raised-button
      [disabled]="button.disableOnValidation ? !form.valid : false"
      [id]="button.id"
      [color]="button.variant"
      (click)="onClick(button.action)"
    >
      {{ button?.text }}
    </button>
    }
  </div>
  @for (button of props['buttons']['rightSetButtons']; track $index) {
  <button
    [disabled]="button.disableOnValidation ? !form.valid : false"
    mat-raised-button
    [id]="button.id"
    [color]="button.variant"
    (click)="onClick(button.action)"
  >
    {{ button?.text }}
  </button>
  }
</div>
}
