import { environment } from '../../../../../../environments/environment';

const baseUrl = `${environment.apiUrl}/action`;
const offersUrl = `${environment.apiUrl}/oms/action`;

export const contributorsDashboardEndpoints = {
  vocabulary: {
    baseUrl: `${baseUrl}/vocabulary`,
    endpoints: {
      getByType: '/getByType',
    },
  },
  providers: {
    baseUrl: `${baseUrl}/providers`,
    endpoints: {
      getAll: '/getAll',
      update: '/update',
      validate: '/validate',
    },
  },
  services: {
    baseUrl: `${baseUrl}/services`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  datasources: {
    baseUrl: `${baseUrl}/datasources`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  trainingResources: {
    baseUrl: `${baseUrl}/training-resources`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  interoperabilityRecords: {
    baseUrl: `${baseUrl}/interoperability-records`,
    endpoints: {
      getAll: '/getAll',
      validate: '/validate',
    },
  },
  adminOffers: {
    baseUrl: `${offersUrl}/admin/offers`,
    endpoints: {
      getParameters: '/parameter',
      getOffers: '/resource',
    },
  },
};
